.sm a {
    color: black !important;
    text-decoration: none !important;
    display: block;
}

.sm a:hover{
    color:#1E00F8 !important;
}

.footer-container {
    margin-top: 6.0em;
    text-align: center;
    justify-content: space-evenly;
    padding-bottom: 4em;
}

.footer-container:before,
.footer-container:after {
    content: "";
}

.footer-container:after {
    clear: both;
}

.footer {
    height: 100vh;
    display: flex;
    position: relative;
    overflow: hidden;
    text-align: center;
}
div {
    display: block;
}

body {
    font-size: 14px;
}

.footer_widget {
    position: relative;
    float: left;
    text-decoration: none;
    margin: left 7px;
}
.copyright {
    margin-top: 1.0em;
    color: #E2001D;
}

.sm{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
    max-width: 500px;
    margin:auto;
}

.sm-content{
    font-size: 26px;
}

.sm-description{
    color: #E2001D;
    font-size: 22px;
    font-weight:600;
}

.sm-content:hover{
    transform: scale(1.2);
}

.sm-content a:hover{
    color:#1E00F8;
}

.filter-white{
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(307deg) brightness(103%) contrast(101%);
}

/** mobile **/
@media screen and (min-width: 0px) and (max-width: 640px) {
    .sm {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 2em;
        padding-left: 72px;
        padding-right:60px;
    }

    .copyright {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-around;
        padding-left: 72px;
        padding-right:60px;
    }
  }