a:link, a:visited, a:hover {
    color: black;
    transform: scale(1.2);
}

.navbar a{
    display: block;
}

.navbar {
    width: 100%;
    height: 72px;
    position: absolute;
    top: 0;
    z-index: 10;
    transition: all 1s ease;

.wrapper {
    padding-left: 4px;
    padding-top: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

    .logo {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 72px;
        font-family: 'ZCOOL KuaiLe', cursive;
        font-size: 33px;
        line-height: 36px;
        font-weight: 700;
        color: #E2001D;
        text-align: left;
    }

    a:hover{
        transform: scale(1.5, 1.5);
    }
}
    .right {
        .list {
            list-style-type: none;
            height: 100%;
            margin-right: 72px;
            padding-right: 10px;
            display: flex;
            justify-content: center;
            align-items: right;
        }

        .items {
            display: block;
            color: black;
            font-family: 'Inconsolata', monospace;
            font-weight: 600;
            font-size: 22px;
            text-align: right;
            text-transform: uppercase;
        }

        .items a:hover{
            color:#1E00F8;
            transform: scale(1.5, 1.5);
        }
        .hamburger {
            display: flex;
        }
    }
}

    @media screen and (min-width: 641px){
        .items {
            display: block;
            color: black;
            font-family: 'Inconsolata', monospace;
            font-weight: 600;
            font-size: 22px;
            text-align: right;
            padding: 0 24px 0 24px;
            text-transform: uppercase;
        }

        #mobile-menu-nav{
            display: none;
        }


    }

    @media screen and (max-width: 640px){
        .right {
            /*hamburger button and animation*/
            .hamburger{
                display: flex;
                height: 100%;
                margin-right: 60px;
                justify-content: center;
                align-items: center;
                position: relative;
                flex-direction: column;

                i {
                    width: 32px;
                    height: 4px;
                    background-color: black;
                    margin: 4px 0;
                    transition:all ease 0.4s;
                }

                .open:nth-child(1){
                    transform: rotate(45deg) translateY(8px) translateX(8px);
                }
                .open:nth-child(2){
                    opacity: 0;
                }
                .open:nth-child(3){
                    transform: rotate(-45deg) translateY(-8px) translateX(8px);
                }

                .close:nth-child(1){
                    transform: rotate(0) translateY(0);
                }
                .close:nth-child(2){
                    opacity: 1;
                }
                .close:nth-child(3){
                    transform: rotate(0) translateY(0);
            }
        }

        #menu-items {
                display: none;
            }
        }

        .header-menu-nav {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 0;
        }

        .header-menu-nav-list {
            transform: translatey(0);
            position: relative;
            flex-grow: 1;
            width: 100%;
            min-height: 100vh;
            height: auto;
            transform: translatey(20px);
            transition: all 0.5s cubic-bezier(.4,0,.2,1);
            background-color: #f8f4e7;
        }

        .mobile-menu{
            position: relative;
            display: flex;
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;
            justify-content: center;
            flex-direction: column;
        }

        .folder-content {
            position: relative;
            display: flex;
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;
            justify-content: center;
            flex-direction: column;
        }

        .menu-item-container {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            padding-left: 4vw;
            padding-right: 4vw;
        }

        .items {
            display: block;
            color: black;
            font-family: 'Inconsolata', monospace;
            font-weight: 600;
            font-size: 32px;
            text-align: left;
            position: relative;
            display: block;
            margin: 3vw 5vw;
            transition: opacity 250ms cubic-bezier(.4,0,.2,1);
            margin-left: 84px;
        }

        .headline {
            margin-top: 72px;
            margin-bottom:16px;
        }

        .mobile-menu p {
            font-size: 22px;
            margin-inline-start: -10%;
        }
    }
}
