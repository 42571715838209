.gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.gallery-content{
    padding-top: 12em;
}

.gallery .grid {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    max-width: calc(100% - 40px);
    margin: auto;
}