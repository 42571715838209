.about {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: 12em;
    padding-bottom: 2em;
}

.about a{
    color: #1E00F8;
    font-weight: 600;
    text-decoration: underline !important;
}

.about-image {
    flex: 1 0 20%;
    text-align: center;
}

.about-image img {
    display: inline-block; 
    width: 275px;
}

.about-text {
    flex: 1 0 50%;
    padding-left: 4em;
    float:right;
}

.about-text > h2 {
    margin: 0;
}

.about h2 {
    font-weight: 600;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: 'Inconsolata', monospace;
}

.currently {
    font-size: 17px;
}

/** mobile **/
@media screen and (min-width: 0px) and (max-width: 640px) {
    .content { font-size: 12pt; }
    
    .about {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-around;
        padding-top: 8em;
        padding-bottom: 2em;
    }

    .about-image {
        flex: 1 0 35%;
        text-align: center;
        padding-left: 72px;
        padding-right: 60px;
    }

    .about-text{
        padding-left: 72px;
        padding-right:60px;
    }
  }

/** tablet **/
  @media screen and (min-width: 641px) and (max-width: 1024px) {
    .content { font-size: 17pt; }
    
    .about {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-around;
        padding-top: 8em;
        padding-bottom: 2em;
    }

    .about-image {
        flex: 1 0 35%;
        text-align: center;
        padding-left: 2em;
    }

    .about-text{
        padding-left: 72px;
        padding-right:60px;
    }
  }